<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <div class="form-box">
        <x-form
          ref="form"
          :rules="rules"
          :model="formData"
        >
          <list>
            <form-item
              label="旧密码："
              prop="oldPassword"
            >
              <x-input
                type="password"
                clear
                auto-size
                autocomplete="off"
                placeholder="请输入"
                v-model="formData.oldPassword"
              />
            </form-item>
            <form-item
              label="新密码："
              prop="password"
            >
              <x-input
                type="password"
                clear
                auto-size
                autocomplete="off"
                placeholder="请输入"
                v-model="formData.password"
              />
            </form-item>
            <form-item
              label="确认密码："
              prop="checkPassword"
            >
              <x-input
                type="password"
                clear
                auto-size
                autocomplete="off"
                placeholder="请输入"
                v-model="formData.checkPassword"
              />
            </form-item>
          </list>
        </x-form>
        <div
          class="btn btn-main submit"
          @click="onSubmit"
        >
          提交
        </div>
      </div>
    </container>
    <!--<div class="footer">
            <div
                class="btn btn-main"
                @click="onSubmit"
            >提交
            </div>
        </div>-->
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import { Valid } from '@/utils';

export default {
  name: 'ChangePassword',
  mixins: [pageMixin],
  data() {
    return {
      formData: {
        oldPassword: this.$globalVar.isDev ? '123456abc' : '',
        password: this.$globalVar.isDev ? '123456abc' : '',
        checkPassword: this.$globalVar.isDev ? '123456abc' : '',
      },
      rules: {
        oldPassword: {
          required: true,
          message: '旧密码不能为空',
        },
        password: {
          required: true,
          message: '新密码不能为空',
        },
        checkPassword: [
          {
            required: true,
            message: '确认密码不能为空',
          },
          {
            validator: Valid.againPassword.bind(this),
            message: '两处密码不相等',
          },
        ],
      },
      routeFrom: {},
    };
  },
  computed: {
    $$form() {
      return this.$refs.form;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeFrom = from;
    });
  },
  methods: {
    onSubmit() {
      this.$$form.validate().then(() => {
        this.$api.Buyer.Mb.Buyer.modPwdByBuyer(this.formData).then(() => {
          // const res = json.data;
          this.$router.replace('/distributor/home');
        });
      });
    },
  },
};
</script>

<style lang="scss">
.form-box {
  padding: $padding-big;
}

.logo {
  text-align: center;
  margin-top: $margin-big * 2;
  > img {
    width: 60%;
  }
}

.submit {
  min-height: 0.9rem;
  margin-top: 0.5rem;
  width: 100%;
}
</style>
