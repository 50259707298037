<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <div class="header">
      <div class="ctrl-left">
        <a
          href="javascript:history.go(-1);"
          class="btn"
        ><i class="f-icon">&#xf011;</i></a>
      </div>
      <div class="label">
        {{ this.$route.meta.title }}
      </div>
      <div class="ctrl-right">
        <a
          href="javascript:;"
          class="btn"
        ><i class="f-icon">&#xf08c;</i></a>
      </div>
    </div>
    <div class="footer">
      <a
        href="javascript:;"
        class="btn"
      >btn</a>
      <a
        href="javascript:;"
        class="btn"
      >btn</a>
    </div>
    <container ref="container">
      404
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';

export default {
  name: 'Error404',
  mixins: [pageMixin],
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: $gray5;
  text-align: center;
}
</style>
