<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <Widget
        :id="2"
        no-show-complete
      />
      <div
        v-if="$globalVar.memberRegisterMode===4"
        class="pa-t pa-l pa-r"
        style="margin: 0.5rem 0 0.2rem 0; "
      >
        <span class="fs-more fw-bold">手机短信登录</span>
      </div>
      <div class="form-box">
        <x-form
          ref="form"
          :rules="rules"
          :model="formData"
        >
          <div
            v-if="$globalVar.isRegisterIHealth"
            class="ma-b tc-red fs-little"
          >
            首次登录请使用注册获得的用户ID作为账号登录！
          </div>
          <list>
            <form-item
              v-if="$globalVar.memberRegisterMode===2 && !$globalVar.isRegisterIHealth"
              label="国家/地区："
              prop="countryCodeId"
              ref="countryCodeId"
            >
              <CountryCode v-model="formData.countryCodeId" />
            </form-item>
            <form-item
              :label="`${$globalVar.isRegisterIHealth?'账号':'手机号'}：`"
              prop="mobile"
              ref="mobile"
            >
              <!--<form-item
                                class="npa-a"
                                prop="countryCodeId">
                                <CountryCode v-model="formData.countryCodeId"/>
                            </form-item>-->
              <x-input
                type="text"
                clear
                :placeholder="$globalVar.isRegisterIHealth?'请输入会员ID或手机号':'请输入'"
                v-model="formData.mobile"
              />
            </form-item>
            <form-item
              v-if="$globalVar.memberRegisterMode===2"
              label="密码："
              prop="password"
            >
              <x-input
                type="password"
                clear
                auto-size
                placeholder="请输入"
                v-model="formData.password"
                @keydown.enter="onKeyDown"
              />
            </form-item>
            <template v-if="$globalVar.memberRegisterMode===4">
              <form-item
                v-if="showJCaptcha"
                label="图片验证码: "
                prop="jCaptcha"
                ref="jCaptcha"
              >
                <x-input
                  v-model="formData.jCaptcha"
                />
                <div
                  class="authCode"
                  @click="onAuthCodeChange"
                >
                  <img
                    :src="captchaSrc"
                    width="100%"
                    height="100%"
                  >
                </div>
              </form-item>
              <form-item
                label="验证码："
                prop="validateCode"
              >
                <x-input
                  auto-size
                  placeholder="请输入"
                  v-model="formData.validateCode"
                  @keydown.enter="onKeyDown"
                />
                <div
                  class="btn btn-main link nbor-a bor-l"
                  @click="()=>{onValidateCode()}"
                  v-if="!countDown"
                >
                  获取验证码
                </div>
                <div
                  class="btn btn-g4 link nbor-a bor-l"
                  v-else
                >
                  重新获取({{ countDown }}秒)
                </div>
              </form-item>
              <!--              <form-item prop="agreement">
                <div class="agreement">
                  &lt;!&ndash;                  <Checkbox v-model="formData.agreement">
                    阅读并接受
                  </Checkbox>&ndash;&gt;
                  查看 {{ $globalVar.shopData.name }}
                  <Linker
                    class="tc-blue"
                    to="/activityPage/5?footerShow&ad"
                  >
                    《个人隐私保护政策及服务使用协议》
                  </Linker>
                </div>
              </form-item>-->
            </template>
          </list>
        </x-form>
        <div
          class="btn btn-main submit"
          @click="onSubmit"
        >
          登录
        </div>
        <template v-if="!$globalVar.isRegisterIHealth">
          <div class="dp-fx fx-row fx-jc-sb fx-ai-c ma-t tc-g6">
            <linker
              :to="{
                path:'./register',
                query:{
                  backUrl
                }
              }"
            >
              注册新用户
            </linker>
            <!--<div></div>-->
            <linker
              :to="{
                path:'./forgotPassword',
                query:{
                  backUrl
                }
              }"
            >
              忘记密码
            </linker>
          </div>
        </template>
        <div
          v-else-if="$globalVar.isRegisterIHealth"
          class="dp-fx fx-col fx-jc-fs fx-ai-c tc-g6"
        >
          <linker
            v-if="$globalVar.iHealthRegisterUrl"
            :to="$globalVar.iHealthRegisterUrl"
            class="btn btn-second bor w-100per ma-t"
            style="min-height: 0.9rem"
          >
            注册新用户
          </linker>
          <linker
            to="https://email.imatrixoffice.com/index.php?pg=password_retrieve&client=ihl"
            class="btn btn-second bor w-100per ma-t"
            style="min-height: 0.9rem"
          >
            找回会员ID
          </linker>
        </div>
        <div
          v-if="$globalVar.memberRegisterMode===4"
          class="agreement"
        >
          查看 {{ $globalVar.shopData.name }}
          <Linker
            class="tc-blue"
            to="/activityPage/5?footerShow&ad"
          >
            《个人隐私保护政策及服务使用协议》
          </Linker>
        </div>
      </div>
    </container>
    <!--<div class="footer">
            <div
                class="btn btn-main"
                @click="onSubmit"
            >登录
            </div>
        </div>-->
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';

export default {
  name: 'Login',
  mixins: [pageMixin],
  data() {
    return {
      countDown: 0,
      countDownTimer: 0,
      formData: {
        countryCodeId: '',
        mobile: this.$globalVar.isDev ? '18659072806' : '',
        password: this.$globalVar.isDev ? '123456' : '',
        jCaptcha: '',
        validateCode: '',
        agreement: true,
      },
      showJCaptcha: false,
      rules: {
        mobile: [
          {
            required: true,
            // message: '手机号不能为空',
            message: this.$globalVar.isRegisterIHealth
              ? '会员ID或手机号不能为空'
              : '手机号不能为空',
          },
          {
            validator: this.accountValid,
            message: '该账号已受限',
          },
          /*{
                        validator: this.$utils.Valid.mobile,
                        message: '手机号格式不正确',
                    },*/
        ],
        countryCodeId: {
          required: true,
          message: '区号不能为空',
        },
        password: {
          required: true,
          message: '密码不能为空',
        },
        jCaptcha: {
          required: true,
          message: '图片验证码不能为空',
        },
        validateCode: {
          required: true,
          message: '短信验证码不能为空',
        },
      },
      captchaSrc: `${
        this.$globalVar.isDev ? 'http://192.168.1.152:8970' : ''
      }/rpc/comm/rs/captcha/get-captcha`,
    };
  },
  computed: {
    $$mobile() {
      return this.$refs.mobile;
    },
    $$form() {
      return this.$refs.form;
    },
    backUrl() {
      return this.$route.query.backUrl;
    },
  },
  beforeRouteEnter(to, from, next) {
    const backUrl = to.query.backUrl;
    if (!backUrl) {
      next({
        ...to,
        replace: true,
        query: {
          ...to.query,
          backUrl: from.fullPath,
        },
      });
      return;
    }
    next();
  },
  methods: {
    onAuthCodeChange() {
      this.captchaSrc = `${
        this.$globalVar.isDev ? 'http://192.168.1.152:8970' : ''
      }/rpc/comm/rs/captcha/get-captcha?_T=${Date.now()}`;
    },
    onSubmit() {
      if (
        !this.formData.agreement &&
        this.$globalVar.memberRegisterMode === 4
      ) {
        this.$messageBox.alert('请勾选“阅读并接受用户注册协议”');
        return;
      }
      const isRegisterIHealth = this.$globalVar.isRegisterIHealth;
      this.$$form.validate().then(() => {
        this.$nprogress.start();
        this.$api.Buyer.Mb.Buyer.login({
          ...this.formData,
          isMd5: !isRegisterIHealth,
        })
          .then(json => {
            this.$nprogress.done();
            // const res = json.data;
            const res = json.data;
            if (!res.success) {
              this.$messageBox.alert(res.msg, '温馨提示').then(() => {
                // 当前用户已登录
                if (res.code === 4010) {
                  // window.location.href = window.location.href.split('#')[0];
                  this.$globalVar.routerEdReplace = true;
                  this.$router.replace({
                    path: `${isRegisterIHealth ? '/' : this.backUrl || '/'}`,
                  });
                }
              });
              return;
            }
            // window.location.href = window.location.href.split('#')[0];
            this.$globalVar.routerEdReplace = true;
            this.$router.replace({
              path: `${isRegisterIHealth ? '/' : this.backUrl || '/'}`,
            });
          })
          .catch(() => {
            this.$nprogress.done();
          });
      });
    },
    onKeyDown() {
      this.onSubmit();
    },
    accountValid(rule, value, callback) {
      // ID：44783401
      // 该账号已受限
      const val = (value + '').replace(/^\s+|\s+$/gm, '');
      if (val === '44783401') {
        callback(new Error(rule.message));
      } else {
        callback();
      }
    },
    startCountDown(val) {
      this.countDown = val;
      clearInterval(this.countDownTimer);
      this.countDownTimer = setInterval(() => {
        this.countDown--;
        if (this.countDown <= 0) {
          this.onAuthCodeChange();
          clearInterval(this.countDownTimer);
          this.countDownTimer = 0;
        }
      }, 1000);
    },
    async onValidateCode() {
      this.$$form.clearValidate();
      await this.$$mobile.validate();
      const { jCaptcha } = this.$refs;
      if (jCaptcha) await jCaptcha.validate();
      const { data: res } = await this.$api.Comm.Rs.Sms.sendVerificationCode({
        ...this.formData,
        _isHandleError: true,
      });
      const { code, success, msg } = res;
      if (!success) {
        if (code === 1002) {
          this.showJCaptcha = true;
          this.$messageBox.tips(msg);
          return;
        }
        this.$messageBox.alert(msg);
        return;
      }
      this.startCountDown(60);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-box {
  padding: $padding-big;
}

.logo {
  text-align: center;
  margin-top: $margin-big * 2;
  > img {
    width: 60%;
  }
}

.authCode {
  width: 2rem;
  height: 0.58rem;
  line-height: 0;
}

.submit {
  min-height: 0.9rem;
  margin-top: 0.5rem;
  width: 100%;
}

.agreement {
  /*display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;*/
  font-size: 90%;
  color: $gray6;
  margin-top: $margin-big * 2;
}
</style>
