<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <list>
        <div
          class="offline-payment-item"
          v-for="(item) in listData"
          :key="item.id"
        >
          <div class="header">
            <div class="label">
              {{ item.sourceName }}
            </div>
            <div class="status badge badge-second">
              {{ item.paymentStatusName }}
            </div>
          </div>
          <div class="body">
            <list>
              <list-item
                label="付款时间"
                :extra="item.paymentTime | moment('YYYY-MM-DD HH:mm:ss')"
              />
              <list-item label="支付金额">
                <div
                  slot="extra"
                  class="extra price"
                >
                  {{ item.paymentAmount | price }}
                </div>
              </list-item>
              <list-item
                label="付款户名"
                :extra="item.payer"
              />
              <list-item
                label="付款备注"
                :extra="item.paymentComments"
              />
              <list-item
                class="reverse"
                extra="付款凭证"
              >
                <ImgList
                  class="label fx-jc-fe"
                  slot="label"
                  :column="4"
                  :max-show-quantity="4"
                  :data="item.payVoucherUrlList"
                />
              </list-item>
              <template v-if="item.paymentStatus === 1 || item.paymentStatus === 3">
                <list-item
                  label="审核时间"
                  :extra="item.payAuditTime | moment('YYYY-MM-DD HH:mm:ss')"
                />
                <list-item
                  label="审核备注"
                  :extra="item.payAuditComments"
                />
              </template>
            </list>
          </div>
        </div>
      </list>
      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled"
        @infinite="onInfinite"
      />
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'GrowthList',
  mixins: [pageMixin, infiniteLoading],
  data() {
    return {
      listData: [],
    };
  },
  methods: {
    getListData({ currentPage, pageSize } = {}) {
      return this.$api.Buyer.Mb.Buyer.offlinePaymentList({
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data.data;
        this.listData = this.listData.concat(res);
        return Promise.resolve(json);
      });
    },
  },
};
</script>

<style lang="scss">
.offline-payment-item {
  background-color: #fff;
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    border-bottom: 1px solid $color-border;
    > .label {
      font-weight: bold;
    }
  }
  + .offline-payment-item {
    margin-top: $margin;
  }
}
</style>
