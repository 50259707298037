<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <container ref="container">
      <Widget
        :id="4"
        no-show-complete
      />
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';

export default {
  name: 'Contact',
  mixins: [pageMixin],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
</style>
