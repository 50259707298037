<!-- Created by henian.xu on 2021/11/3. -->

<template>
  <Page>
    <container ref="container">
      <div
        class="pa-t pa-l pa-r"
        style="margin: 0.5rem 0 0.2rem 0; "
      >
        <span class="fs-more fw-bold">绑定手机号</span>
      </div>

      <div class="form-box">
        <x-form
          ref="form"
          :rules="rules"
          :model="formData"
        >
          <list>
            <form-item
              v-if="$globalVar.memberRegisterMode===2"
              label="国家/地区："
              prop="countryCodeId"
              ref="countryCodeId"
            >
              <CountryCode v-model="formData.countryCodeId" />
            </form-item>
            <form-item
              label="手机号："
              prop="mobile"
              ref="mobile"
            >
              <x-input
                type="text"
                clear
                placeholder="请输入"
                v-model="formData.mobile"
              />
            </form-item>
            <form-item
              v-if="showJCaptcha"
              label="图片验证码: "
              prop="jCaptcha"
              ref="jCaptcha"
            >
              <x-input
                v-model="formData.jCaptcha"
              />
              <div
                class="authCode"
                @click="onAuthCodeChange"
              >
                <img
                  :src="captchaSrc"
                  width="100%"
                  height="100%"
                >
              </div>
            </form-item>
            <form-item
              label="验证码："
              prop="validateCode"
            >
              <x-input
                auto-size
                placeholder="请输入"
                v-model="formData.validateCode"
                @keydown.enter="onKeyDown"
              />
              <div
                class="btn btn-main link nbor-a bor-l"
                @click="()=>{onValidateCode()}"
                v-if="!countDown"
              >
                获取验证码
              </div>
              <div
                class="btn btn-g4 link nbor-a bor-l"
                v-else
              >
                重新获取({{ countDown }}秒)
              </div>
            </form-item>
          </list>
        </x-form>
        <div
          class="btn btn-main submit"
          @click="onSubmit"
        >
          绑定
        </div>
      </div>
    </container>
  </Page>
</template>

<script>
export default {
  name: 'BindMobilePage',
  data() {
    return {
      countDown: 0,
      countDownTimer: 0,
      formData: {
        countryCodeId: '',
        mobile: this.$globalVar.isDev ? '18659072806' : '',
        jCaptcha: '',
        validateCode: '',
      },
      showJCaptcha: false,
      rules: {
        mobile: [
          {
            required: true,
            message: '手机号不能为空',
          },
          /*{
                        validator: this.$utils.Valid.mobile,
                        message: '手机号格式不正确',
                    },*/
        ],
        countryCodeId: {
          required: true,
          message: '区号不能为空',
        },
        jCaptcha: {
          required: true,
          message: '图片验证码不能为空',
        },
        validateCode: {
          required: true,
          message: '短信验证码不能为空',
        },
      },
      captchaSrc: `${
        this.$globalVar.isDev ? 'http://192.168.1.152:8970' : ''
      }/rpc/comm/rs/captcha/get-captcha`,
    };
  },
  computed: {
    $$mobile() {
      return this.$refs.mobile;
    },
    $$form() {
      return this.$refs.form;
    },
    backUrl() {
      return this.$route.query.backUrl;
    },
  },
  beforeRouteEnter(to, from, next) {
    const backUrl = to.query.backUrl;
    if (!backUrl) {
      next({
        ...to,
        replace: true,
        query: {
          ...to.query,
          backUrl: from.fullPath,
        },
      });
      return;
    }
    next();
  },
  methods: {
    onAuthCodeChange() {
      this.captchaSrc = `${
        this.$globalVar.isDev ? 'http://192.168.1.152:8970' : ''
      }/rpc/comm/rs/captcha/get-captcha?_T=${Date.now()}`;
    },
    onSubmit() {
      this.$$form.validate().then(() => {
        this.$api.Buyer.Mb.Buyer.bindMobile(this.formData).then(json => {
          // const res = json.data;
          const res = json.data;
          if (!res.success) {
            this.$messageBox.alert(res.msg, '温馨提示').then(() => {
              // 当前用户已登录
              if (res.code === 4010) {
                // window.location.href = window.location.href.split('#')[0];
                this.$globalVar.routerEdReplace = true;
                this.$router.replace({
                  path: `${this.backUrl || '/'}`,
                });
              }
            });
            return;
          }
          // window.location.href = window.location.href.split('#')[0];
          this.$globalVar.routerEdReplace = true;
          this.$router.replace({
            path: `${this.backUrl || '/'}`,
          });
        });
      });
    },
    onKeyDown() {
      this.onSubmit();
    },
    startCountDown(val) {
      this.countDown = val;
      clearInterval(this.countDownTimer);
      this.countDownTimer = setInterval(() => {
        this.countDown--;
        if (this.countDown <= 0) {
          this.onAuthCodeChange();
          clearInterval(this.countDownTimer);
          this.countDownTimer = 0;
        }
      }, 1000);
    },
    async onValidateCode() {
      this.$$form.clearValidate();
      await this.$$mobile.validate();
      const { jCaptcha } = this.$refs;
      if (jCaptcha) await jCaptcha.validate();
      const { data: res } = await this.$api.Comm.Rs.Sms.sendVerificationCode({
        ...this.formData,
        _isHandleError: true,
      });
      const { code, success, msg } = res;
      if (!success) {
        if (code === 1002) {
          this.showJCaptcha = true;
          this.$messageBox.tips(msg);
          return;
        }
        this.$messageBox.alert(msg);
        return;
      }
      this.startCountDown(60);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-box {
  padding: $padding-big;
}

.logo {
  text-align: center;
  margin-top: $margin-big * 2;
  > img {
    width: 60%;
  }
}

.authCode {
  width: 2rem;
  height: 0.58rem;
  line-height: 0;
}

.submit {
  min-height: 0.9rem;
  margin-top: 0.5rem;
  width: 100%;
}

.agreement {
  /*display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;*/
  font-size: 90%;
  color: $gray6;
  margin-top: $margin-big * 2;
}
</style>
