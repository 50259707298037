<!-- Created by henian.xu on 2017/10/22. -->
<template>
  <Page>
    <tabs
      class="average second-header"
      @tabChange="onTabsChange"
    >
      <tab-panel
        :label="`未使用(${dataInfo.couponType1Num||0})`"
        :sign="1"
        :active-panel="type===1"
      />
      <tab-panel
        :label="`已使用(${dataInfo.couponType2Num||0})`"
        :sign="2"
        :active-panel="type===2"
      />
      <tab-panel
        :label="`已过期(${dataInfo.couponType3Num||0})`"
        :sign="3"
        :active-panel="type===3"
      />
    </tabs>
    <query-box
      class="second-header"
      top=".89rem"
      icon="&#xf00d;"
      placeholder="请输入优惠码"
      show-search-btn
      search-btn-str="兑换"
      v-model="couponCodes"
      @query="onConvertCoupon"
    />
    <container ref="container">
      <list class="pa-a">
        <coupon-item
          v-for="(item) in listData"
          :key="item.id"
          :data="item"
        />
      </list>
      <infinite-loading
        ref="infinite"
        :disabled="infiniteDisabled"
        @infinite="onInfinite"
      >
        <div
          class="ta-c pa-a ma-t"
          slot="no-result"
        >
          <img
            class="img-obj"
            style="opacity: .3;"
            src="~@/assets/images/icon/icon02.png"
            width="40%"
          >
          <div class="tc-g6">
            暂无可用优惠券
          </div>
        </div>
      </infinite-loading>
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import infiniteLoading from '@/mixins/infiniteLoading';

export default {
  name: 'PointList',
  mixins: [pageMixin, infiniteLoading],
  data() {
    return {
      listData: [],
      dataInfo: {},
      couponCodes: '',
    };
  },
  computed: {
    /**
     * 1未使用 2已使用 3已过期
     * @returns {number}
     */
    type() {
      return +this.$route.params.type;
    },
  },
  methods: {
    getListData({ type = this.type, currentPage, pageSize } = {}) {
      return this.$api.Buyer.Mb.Coupon.list({
        couponType: type,
        currentPage:
          currentPage || this.pagination.currentPage
            ? this.pagination.currentPage + 1
            : 1,
        pageSize,
      }).then(json => {
        const res = json.data.data;
        this.listData = this.listData.concat(res.couponList.data);
        this.dataInfo = res;
        return Promise.resolve({ data: res.couponList });
      });
    },
    onTabsChange(item) {
      this.$router.replace({
        path: `./${item.sign}`,
        query: {
          ...this.$router.query,
        },
      });
    },
    refreshData() {
      this.infiniteDisabled = true;
      this.pagination = {};
      this.listData = [];
      this.$nextTick(() => (this.infiniteDisabled = false));
    },
    onConvertCoupon() {
      const { couponCodes } = this;
      if (!couponCodes) return;
      console.log(couponCodes);
      this.$api.Buyer.Mb.Coupon.receiveCoupon({
        couponCodes,
      }).then(json => {
        const result = json.data;
        if (!result.success) {
          this.$messageBox.tips(result.msg, 3000);
          return;
        }
        this.$messageBox
          .alert('<div class="ta-c">领取成功</div>', '领券成功')
          .then(() => {
            this.refreshData();
          });
      });
    },
  },
};
</script>

<style lang="scss">
</style>
