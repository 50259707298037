<!-- Created by henian.xu on 2018/9/12. -->

<template>
  <Page :style="pageStyles">
    <div class="second-header">
      <query-box
        class="decoration-query-box"
        top=".89rem"
        placeholder="请输入商品名称"
        show-search-btn
        @input="(val)=>{this.keywords=val}"
        @query="onSearch"
      />
      <div class="extra">
        <template v-if="$globalVar.meiQiaEntId">
          <div
            v-if="meiQiaAllSet"
            class="btn"
            @click="onService"
          >
            <i class="f-icon fs-more">&#xf024;</i>
          </div>
        </template>
        <div
          v-else
          class="btn"
          @click="onService"
        >
          <i class="f-icon fs-more">&#xf024;</i>
        </div>
      </div>
    </div>
    <container
      class="container"
      ref="container"
    >
      <div class="bg" />
      <Widget
        class="widget"
        @decorationData="onDecorationData"
        v-if="decorationJson"
        :json="decorationJson"
      />
    </container>
  </Page>
</template>

<script>
import pageMixin from '@/mixins/layout/page';
import meiQiaMixin from 'mixins/meiQiaMixin';
import MeiQiaControl from 'store/meiQia/MeiQiaControl';
export default {
  name: 'Decoration',
  mixins: [pageMixin, meiQiaMixin],
  data() {
    return {
      keywords: '',
      decorationJson: '',
      pageInfo: {},
    };
  },
  head() {
    const meta = this.$route.meta;
    const { pageTitle } = this;
    if (pageTitle) {
      this.$bus.$emit('pageTitle', pageTitle);
    }
    return {
      title:
        pageTitle ||
        `${meta.title || ''}${meta.subTitle ? ` - ${meta.subTitle}` : ''}`,
    };
  },
  computed: {
    pageTitle() {
      const { pageInfo } = this;
      const { props } = pageInfo;
      if (!props || !props.title) return '';
      return props.title;
    },
    pageStyles() {
      const { pageInfo } = this;
      const { props } = pageInfo;
      if (!props || !props.backgroundColor) return {};
      return {
        'background-color': props.backgroundColor,
      };
    },
  },
  methods: {
    onSearch() {
      if (!this.keywords) return;
      this.$router.push(`/goods/search/${encodeURIComponent(this.keywords)}`);
    },
    onDecorationData(data) {
      this.pageInfo = data.find(item => item.componentType === 'Page');
      // console.log(this.pageInfo);
    },
    onService() {
      if (!this.$globalVar.userData.id) {
        this.$router.replace('/buyer/login');
        return;
      }
      if (this.meiQiaAllSet) {
        if (
          !this.$globalVar.meiQiaCustomerService ||
          !this.$globalVar.meiQiaCustomerService.length
        ) {
          MeiQiaControl.showPanel();
          return;
        }
        this.$router.push('/feedback/customerService');
      } else {
        // this.$router.push('/feedback/list');
        window.location.href = this.$globalVar.feedbackUrl;
      }
    },
  },
  created() {
    this.$api.Buyer.Sp.ShopDecoration.getShopIndexJson().then(json => {
      let res = json.data.data;
      this.decorationJson = res;
    });
  },
};
</script>

<style lang="scss" scoped>
.second-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  > .decoration-query-box {
    flex: 1 1 1%;
  }

  > .extra {
    flex: 0 0 auto;
    background-color: $color-main;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > .btn {
      padding-left: 0;
    }
  }
}
.container {
  > .bg {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    height: 2.6rem;
    background-image: linear-gradient(
      to bottom,
      $color-main 0%,
      rgba(255, 255, 255, 0) 2.6rem
    );
  }
  > .widget {
    position: relative;
    z-index: 10;
  }
}
</style>
<style lang='scss'>
.decoration-query-box {
  > .search-box {
    background-color: $color-main;
  }
}
</style>
